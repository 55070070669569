<template>
  <div>
    <Grid :key="key"
          :service="service"
          route_name="questionnaire_correctors"
          :route_grid_path="`corrections/grid`"
          :column_formats="{
            'status': (val) => $t(`questionnaire_corrector.status.${val}`),
            'user_id': (val) => $t(`usuario`)+ ' - '+val
          }"
          :css_formats="{
            status: (val) => {
              switch (val) {
              case (null):
                return 'text-danger'
              case ('CP'):
                return 'text-danger'
              case ('IC'):
                return 'text-warning'
              case ('SR'):
                return 'text-primary'
              default:
                return ''
              }
            }
          }"
        >
        <template v-slot:actions="actions">
          <feather-icon
            icon="CheckIcon"
            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-primary"
            v-tooltip.auto="{
              content: $t('action.open_correction'),
              delay: {
                show: 500,
                hide: 500
              }
            }"
            @click="() => openCorrection(actions.row)"/>
        </template>
    </Grid>
  </div>
</template>

<script>
import QuestionnaireCorrectorService from '@/services/api/QuestionnaireCorrectorService'

export default {
  data: () => ({
    service: null
  }),
  methods: {
    openCorrection(entity) {
      const questionnaireCorrectorId = entity.questionnaire_corrector_id //questionnaire_corrector_id
      const answerId = entity.id //answer_id
      this.$router.push(`/questionnaire_correctors/${questionnaireCorrectorId}/answer/${answerId}/correction`)
    }
  },
  beforeMount() {
    this.service = QuestionnaireCorrectorService.build(this.$vs)
  }
}
</script>
