var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Grid", {
        key: _vm.key,
        attrs: {
          service: _vm.service,
          route_name: "questionnaire_correctors",
          route_grid_path: "corrections/grid",
          column_formats: {
            status: function (val) {
              return _vm.$t("questionnaire_corrector.status." + val)
            },
            user_id: function (val) {
              return _vm.$t("usuario") + " - " + val
            },
          },
          css_formats: {
            status: function (val) {
              switch (val) {
                case null:
                  return "text-danger"
                case "CP":
                  return "text-danger"
                case "IC":
                  return "text-warning"
                case "SR":
                  return "text-primary"
                default:
                  return ""
              }
            },
          },
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function (actions) {
              return [
                _c("feather-icon", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.auto",
                      value: {
                        content: _vm.$t("action.open_correction"),
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      },
                      expression:
                        "{\n            content: $t('action.open_correction'),\n            delay: {\n              show: 500,\n              hide: 500\n            }\n          }",
                      modifiers: { auto: true },
                    },
                  ],
                  attrs: {
                    icon: "CheckIcon",
                    svgClasses: "h-5 w-5 mb-1 mr-3 stroke-current text-primary",
                  },
                  on: {
                    click: function () {
                      return _vm.openCorrection(actions.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }